import type {
  EmbedInteropDimensionChangeEvent,
  EmbedInteropScreenChangeEvent,
  EmbedInteropBackgroundClickEvent,
  EmbedInteropEvent,
} from "./types"

const emit = (event: EmbedInteropEvent) => window.parent.postMessage(event, "*")

const EmbedInterop = {
  // Internal events not used for analytics but embedding functionality
  sendScreenChangeEvent(advisorId: string, screenId: string) {
    emit({
      advisorId: advisorId,
      type: "screen-change",
      data: { screenId },
    } as EmbedInteropScreenChangeEvent)
  },

  sendDimensionChangeEvent(
    advisorId: string,
    dimensions: {
      height: number
      width: number
    }
  ) {
    emit({
      advisorId: advisorId,
      type: "viewport-dimensions",
      data: {
        height: dimensions.height,
        width: dimensions.width,
      },
    } as EmbedInteropDimensionChangeEvent)
  },

  sendBackgroundClickEvent(advisorId: string) {
    emit({
      advisorId: advisorId,
      type: "background-click",
      data: {},
    } as EmbedInteropBackgroundClickEvent)
  },

  closeModal(advisorId: string) {
    this.sendBackgroundClickEvent(advisorId)
  },
}

export default EmbedInterop
