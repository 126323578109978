<script lang="ts">
  export let type: "grid" | "list" = "list"
</script>

<div class="container">
  <div
    class="layout"
    class:is-grid={type === "grid"}
    class:is-list={type === "list"}
  >
    <slot></slot>
  </div>
</div>

<style>
  .container {
    container-type: inline-size;
  }
  .layout {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 0.5rem;
    align-items: start;
  }
  .is-grid,
  .is-list {
    display: grid;
    /* flex-wrap: wrap; */
    gap: 1rem;
    grid-template-columns: minmax(0, 1fr);
    align-items: stretch;
  }
  .is-grid > :global(*),
  .is-list > :global(*) {
    /* max-width: 400px !important; */
    display: grid;
    align-content: start;
  }
  .is-grid > :global(*) {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  @container (min-width: 400px) {
    .is-grid {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    }
    .is-grid > :global(*) {
      max-width: 560px;
      margin-left: none;
      margin-right: none;
    }
  }
  @container (min-width: 50rem) {
    .is-list {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    }
    .is-grid {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    }
  }
  @container (min-width: 60rem) {
    .is-list {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    }
  }
  @container (min-width: 80rem) {
    .is-grid {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(
          0,
          1fr
        );
    }
    .is-list {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(
          0,
          1fr
        );
    }
  }
</style>
