<script lang="ts">
  import ModalBackground from "../ModalBackground.svelte"
  import DimensionsEmitter from "../DimensionsEmitter.svelte"
  import TopNavigation from "../ui/TopNavigation.svelte"
  import { navigationContext } from "../stores/navigation"
  import SidebarBackgroundNewStyling from "../SidebarBackgroundNewStyling.svelte"
  import PoweredByAiden from "../PoweredByAiden.svelte"
  import type { Page } from "./toPage"
  import EmbedInterop from "../embed-interop/EmbedInterop"

  export let advisorId: string
  export let screenId: string
  export let progress: number
  export let hideProgress: boolean
  export let hideStartOver: boolean
  export let page: Page | undefined
  export let showPoweredByAiden: boolean

  let onClose = () => EmbedInterop.closeModal(advisorId)
  $: onClose = () => EmbedInterop.closeModal(advisorId)

  export let embedMode: "fullpage" | "modal" | "sidebar"
</script>

<div
  class="wrapper"
  class:is-advice-page={page?.type === "advice-page" ||
    page?.type === "product-check-advice-page"}
  class:is-empty-advice-page={page?.type === "empty-advice-page"}
  class:is-start-page={page?.type === "start-page"}
>
  {#if embedMode === "fullpage"}
    <DimensionsEmitter {advisorId}>
      <div
        class="fullpage-frame page"
        class:legacy-minimal-content={page?.type === "start-page" &&
          page.legacyShowOnlyButton}
      >
        <TopNavigation
          onStartOver={$navigationContext.reset}
          {onClose}
          {progress}
          {hideProgress}
          {hideStartOver}
          hideClose
        />
        <slot />
        {#if showPoweredByAiden}
          <div class="powered-by-aiden">
            <PoweredByAiden />
          </div>
        {/if}
      </div>
    </DimensionsEmitter>
  {:else if embedMode === "modal"}
    <ModalBackground {advisorId}>
      <div class="modal-frame page">
        <TopNavigation
          onStartOver={$navigationContext.reset}
          {onClose}
          {progress}
          {hideProgress}
          {hideStartOver}
        />
        <slot />
        {#if showPoweredByAiden}
          <div class="powered-by-aiden">
            <PoweredByAiden />
          </div>
        {/if}
      </div>
    </ModalBackground>
  {:else if embedMode === "sidebar"}
    <SidebarBackgroundNewStyling {advisorId} {screenId}>
      <div class="sidebar-frame page">
        <TopNavigation
          onStartOver={$navigationContext.reset}
          {onClose}
          {progress}
          {hideProgress}
          {hideStartOver}
        />
        <div class="sidebar-content-wrapper">
          <slot />
        </div>

        {#if showPoweredByAiden}
          <div class="powered-by-aiden">
            <PoweredByAiden />
          </div>
        {/if}
      </div>
    </SidebarBackgroundNewStyling>
  {/if}
</div>

<style>
  .modal-frame {
    max-width: 48rem;
    margin: 0 auto;
  }
  .wrapper {
    --frame-padding: 1rem;
    /* --aiden-app-background-color: var(--aiden-app-background-color); */
  }
  @media screen and (min-width: 640px) {
    .wrapper {
      --frame-padding: 2rem;
    }
  }

  .sidebar-frame {
    display: grid;
    grid-template-rows: min-content 1fr min-content;
  }
  .is-start-page .sidebar-frame {
    grid-template-rows: minmax(min-content, 20vh) 1fr min-content;
  }

  .powered-by-aiden {
    margin-left: auto;
    width: 100%;
    display: flex;
    justify-content: end;
    box-sizing: border-box;
    padding: 2.5rem 0.5rem 0.5rem 1rem;
  }

  .page {
    --plane-color: var(--aiden-app-background-color, #fff);
    background-color: var(--plane-color);
  }
  .is-advice-page .page,
  .is-empty-advice-page .page {
    --plane-color: var(--aiden-advice-page-header-background-color, #fff);
    --aiden-top-navigation-background-color: var(
      --aiden-advice-page-header-background-color,
      #fff
    );
    background-color: var(--plane-color);
  }

  .is-empty-advice-page .sidebar-content-wrapper,
  .is-advice-page .sidebar-content-wrapper {
    background-color: var(--aiden-advice-page-bottom-background-color,);
  }

  .is-start-page .modal-frame {
    padding-bottom: .5rem;
  }
  .is-start-page .modal-frame,
  .is-start-page .page {
    --plane-color: var(--aiden-start-page-background-color, #fff);
    --aiden-app-background-color: var(--plane-color);
    background-color: var(--plane-color);
    --aiden-navigation-button-color: var(
      --aiden-start-page-navigation-button-color
    );
    --aiden-navigation-button-background-color-hover: var(
      --aiden-start-page-navigation-button-background-color-hover
    );
    --aiden-navigation-button-color-hover: var(
      --aiden-start-page-navigation-button-color-hover
    );
  }

  .is-advice-page,
  .is-empty-advice-page {
    --aiden-navigation-button-color: var(
      --aiden-advice-page-top-navigation-button-color
    );

    --aiden-navigation-button-color-hover: var(
      --aiden-advice-page-top-navigation-button-color-hover
    );
    --aiden-navigation-button-color: var(
      --aiden-advice-page-top-navigation-button-color
    );
  }

  .is-advice-page .powered-by-aiden {
    background-color: var(
      --aiden-advice-page-bottom-background-color,
      var(--aiden-app-background-color, #fff)
    );
  }

  .is-start-page .legacy-minimal-content {
    --plane-color: transparent;
    --frame-padding: 0;
    /* background-color: transparent; */
  }
  .is-start-page .legacy-minimal-content .powered-by-aiden {
    display: none;
  }
</style>
