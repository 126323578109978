<script lang="ts">
  import { v4 as uuidv4 } from "uuid"
  import { actionStore } from "../../actions/ActionStore"
  import { type ActionState } from "../../actions/types"
  import { advisorInfoStore } from "../../stores"
  import Loader from "../../icons/Loader.svelte"
  import Success from "../../icons/Success.svelte"
  import Cart from "../../icons/Cart.svelte"
  import Cross from "../../icons/Cross.svelte"
  import Button from "../Button.svelte"
  import { productClickHandler as productClickHandler_ } from "../../app/productClickHandler"

  import { appAnalyticsStore } from "../../analytics/appAnalytics"
  import type { Recommendation } from "../../app/toPage"
  import type { InternalAnalyticsProduct } from "../../analytics/internal/types"

  export let recommendation: Recommendation
  export let actionIdentifier: string

  const triggerId = uuidv4()

  export let productClickHandler:
    | ReturnType<typeof productClickHandler_>
    | undefined = undefined

  const onClick = (e: KeyboardEvent | MouseEvent) => {
    e.preventDefault()
    const internalProduct: InternalAnalyticsProduct = {
      id: recommendation.productId,
      productId: recommendation.clientProductId,
      name: recommendation.productName,
      url: recommendation.productUrl,
      price: recommendation.specification.price,
      quantity: 1,
    }

    $appAnalyticsStore.customActionEvent(
      actionIdentifier,
      { ...recommendation.recommendationContext, product: internalProduct },
      "ADVICE_PAGE",
      "PRODUCT_CTA",
      [internalProduct]
    )

    productClickHandler?.measureProductClick(
      recommendation,
      "LINK",
      "PRODUCT_CTA"
    )

    actionStore.trigger(triggerId, {
      advisorId: $advisorInfoStore.advisorId,
      advisorName: $advisorInfoStore.advisorName,
      actionId: actionIdentifier,
      products: [
        {
          id: recommendation.clientProductId,
          name: recommendation.productName,
          quantity: 1,
        },
      ],
    })
  }

  let status: ActionState = "IDLE"
  $: {
    status = $actionStore[triggerId] || "IDLE"
  }
</script>

<Button
  isFullWidth
  variant="primary"
  {onClick}
  testId="action-button"
  className="action-button"
>
  {#if status === "PENDING" || status === "ACKNOWLEDGED"}
    <Loader />
  {:else if status === "SUCCESS"}
    <Success />
  {:else if status === "FAILED"}
    <Cross />
  {:else}
    <Cart />
  {/if}
  <slot />
</Button>

<style>
  :global(button.action-button) {
    overflow-wrap: break-word;
    hyphens: manual;
    /* background: var(--aiden-product-action-button-background-color, #000);
    border-color: var(--aiden-product-action-button-border-color, #000);
    border-width: var(--aiden-product-action-button-border-width, 1px);
    padding: var(--aiden-product-action-button-vertical-padding, 0.5rem) var(--aiden-product-action-button-horizontal-padding, 1rem);
    color: var(--aiden-product-action-button-color, #FFF);
    font-weight: var(--aiden-product-action-button-font-weight, 600);
    font-family: var(--aiden-product-action-button-font, inherit);
    font-size: var(--aiden-product-action-button-font-size, 0.875rem);
    text-transform: var(--aiden-product-action-button-text-case, none);
    border-radius: var(--aiden-product-action-button-border-radius, 0.75rem); */

    & svg {
      height: 1rem;
      width: 1rem;
      transform: scale(1.25);
      flex-shrink: 0;
    }
  }
</style>
