<script lang="ts">
  export let step: "empty" | "quarter" | "half" | "three-quarter" | "full"
</script>

<div
  class="star-container"
  class:is-empty={step === "empty"}
  class:is-quarter={step === "quarter"}
  class:is-half={step === "half"}
  class:is-three-quarter={step === "three-quarter"}
  class:is-full={step === "full"}
>
  <div class="star">
    <svg viewBox="0 0 24 24" width=20 height=20>
      <path
        d="M11.0953842,2.57377216 L8.507,8.065 L2.8494967,8.92712409 L2.73767743,8.95036744 C2.01447036,9.14254148 1.74242535,10.060916 2.28560079,10.615472 L6.403,14.819 L5.41882485,20.8384453 L5.40686558,20.9446103 C5.35970881,21.7135742 6.18927099,22.2610135 6.888293,21.8758385 L12,19.059 L17.111707,21.8758385 L17.2077497,21.9226515 C17.9166087,22.2245292 18.7102079,21.6259881 18.5811536,20.8383136 L17.595,14.819 L21.7143293,10.6155433 L21.7897503,10.5297692 C22.2523983,9.9415626 21.91795,9.04394128 21.1504821,8.92712085 L15.491,8.065 L12.904668,2.57388286 C12.5442783,1.80875746 11.4558675,1.80869087 11.0953842,2.57377216 Z"
        fill="currentColor"
      ></path>
    </svg>
  </div>
</div>

<style>
  .star-container {
    width: var(--star-width, 20px);
    box-sizing: border-box;
  }
  .star {
    height: var(--star-width, 20px);
    overflow: hidden;
  }

  .is-empty {
    width: 0;
  }
  .is-quarter {
    width: 30%;
  }
  .is-half {
    width: 50%;
  }
  .is-three-quarter {
    width: 70%;
  }
  .is-full {
    width: 100%;
  }
</style>
