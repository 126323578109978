<script lang="ts">
  import type { ProductField } from "../../types"
  import { removeHTMLMarkup } from "./utils/removeHTMLMarkup"

  export let productFields: ProductField[]

  let sanitizedFields: { values: string; label: string; suffix: string }[] = []
  $: sanitizedFields = removeHTMLMarkup(productFields)
</script>

<div class="product-fields">
  {#each sanitizedFields as field}
    <div class="field">
      {#if field.label.trim()}
        <span class="field-label">{field.label}:</span>
      {/if}
      <span class="field-value">
        {field.values}
      </span>
      {#if field.suffix}
        <span class="field-suffix">
          {field.suffix}
        </span>
      {/if}
    </div>
  {/each}
</div>

<style>
  .product-fields {
    padding: 0;
    /* margin: 1rem 0; */
  }

  .field {
    font-size: var(--product-card-small-font-size, 0.875rem);
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 0.25rem;
    margin-bottom: 0.25rem;

    & .field-label {
      font-weight: var(--aiden-product-field-label-font-weight, bold);
      color: var(--aiden-product-field-label-color, inherit);
    }

    & .field-value {
      font-weight: var(--aiden-product-field-value-font-weight, normal);
      color: var(--aiden-product-field-value-color, inherit);
      -webkit-line-clamp: 4;
      line-clamp: 4;
      -webkit-box-orient: vertical;
      min-width: 0;
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    & .field-suffix {
      font-weight: var(--aiden-product-field-suffix-font-weight, normal);
      color: var(--aiden-product-field-suffix-color, inherit);
    }
  }
</style>
