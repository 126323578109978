<script lang="ts">
  export let disabled: boolean = false
</script>

<div class="container">
  <div class="product-card" class:is-disabled={disabled}>
    {#if $$slots["product-card-tags"]}
      <span class="product-card-tags">
        <slot name="product-card-tags" />
      </span>
    {/if}
    <slot name="product-card-image" />
    <div>
      <slot name="product-card-body" />
      <div class="product-card-buttons">
        <slot name="product-card-buttons" />
      </div>
    </div>
    {#if $$slots["product-card-bottom"]}
      <div class="product-card-bottom">
        <slot name="product-card-bottom" />
      </div>
    {/if}
  </div>
</div>

<style>
  .container {
    container: card / inline-size;
    margin: 1.5rem 0;
  }

  .product-card-bottom,
  .product-card-tags {
    grid-column: 1 / -1;
  }
  .product-card-tags {
    position: absolute;
    top: -0.75rem;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .product-card-tags > :global(*) {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  .product-card-bottom > :global(*) {
    display: grid;
    gap: 1rem;
  }
  .product-card-buttons {
    margin: 1rem 0 0 0;
  }

  .product-card {
    --card-line-spacing: 0.5rem;
    --product-card-small-font-size: clamp(
      0.75rem,
      var(--aiden-product-field-font-size, 0.875rem),
      0.875rem
    );

    container-type: inline-size;

    background-color: var(--aiden-product-card-background-color, #fff);
    border-radius: clamp(0rem, var(--aiden-product-card-border-radius), 1.5rem);
    border: 1px solid rgba(125, 125, 125, 0.25);
    /* border: 1px solid
      var(--aiden-product-card-border-color, rgba(29, 38, 77, 0.05)); */
    color: var(--aiden-product-card-text-color, #000);
    font-family: var(--aiden-regular-text-font, "system-ui");
    padding-bottom: 1rem;
    padding: 1rem 1rem 1.5rem 1rem;
    --animation-translate-y-offset: 2rem;
    animation: appear-from-bottom 250ms ease-out;
    position: relative;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 1rem;

    &.is-disabled > * {
      opacity: 0.5;
      pointer-events: none;
    }

    &.is-disabled .product-card-tags {
      opacity: 1;
    }

    &.is-disabled .product-card-image {
      transform-origin: center center;
      max-height: 7rem;
    }
  }

  :global(.is-banner) .product-card {
    background-color: transparent;
  }

  @container card  (min-width: 500px) {
    .product-card {
      align-items: start;
      align-items: center;
      padding: 1.5rem;
      display: grid;
      gap: 1rem 2rem;
      grid-template-columns: minmax(6rem, 16rem) minmax(17rem, 1fr);
      max-width: 48rem;
      margin: 0 auto;
    }
    .product-card-buttons {
      margin: 1rem 0 1rem 0;
    }
  }
  @container card  (min-width: 640px) {
    .product-card {
      gap: 1rem 2rem;
      padding: 2rem;
    }
  }
</style>
