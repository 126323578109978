import type { Translations } from "../types"

const translations: Translations = {
  showMore: "Покажи повече",
  showLess: "Покажи по-малко",
  bestMatch: "Най-добър мач",
  view: "Да гледам",
  customAction: "В пазарската количка",
  notFound: "404: Не е намерено",
  reset: "Започнете отначало",
  close: "Слуитен",
  noAdviceTitle:
    "В момента няма налични продукти, които да отговарят напълно на вашите предпочитания.",
  noAdviceMessage: "Можете да промените предпочитанията си или да опитате отново по-късно.",
  prev: "Предишен",
  next: "Следваща",
  backToAdvice: "Обратно към съвета",
  dontKnow: "Пропускане",
  email: "Имейл адрес",
  send: "Изпратете",
  emailError: "Моля, въведете валиден имейл адрес",
  readMore: "Прочетете повече",
  selectAnAnswer: "Изберете отговор",
  answerNotListed: "Отговорът ми не е в списъка",
  whyThisProductForMe: "Защо този съвет?",
  alternative: "алтернатива",
  alternatives: "алтернативи",
  seeAlso: "Вижте също",
}

export default translations
