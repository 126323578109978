<script lang="ts">
  import type { ProductInContextClick } from "../../../app/productClickHandler"
  import ContextMenuHandler from "../../../contextMenuHandler"

  export let productUrl: string | undefined
  export let productName: string
  export let onClick: ProductInContextClick | undefined = undefined

  export let linkTarget: "_parent" | "_blank"
</script>

{#if productUrl}
  <a
    class="product-name"
    href={productUrl}
    target={linkTarget}
    data-testid="product-card-product-name"
    on:click={(e) => onClick && onClick(e, "PRODUCT_TITLE", "LINK")}
    use:ContextMenuHandler={() =>
      onClick && onClick(undefined, "PRODUCT_TITLE", "LINK_RIGHT_CLICK")}
  >
    {productName}
  </a>
{:else}
  <div data-testid="product-card-product-name" class="product-name">
    {productName}
  </div>
{/if}

<style>
  .product-name {
    color: var(--aiden-product-name-color, #1d264d);
    font-weight: var(--aiden-product-name-font-weight, bold);
    font-size: 1rem;
    font-family: var(--aiden-product-name-font-family, inherit);
    line-height: 1.25;
    text-decoration: none;
    margin-bottom: 0.25rem;
  }

  a.product-name:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }

  @container card  (min-width: 500px) {
    .product-name {
      font-size: 1.125rem;
    }
  }
  @container card  (min-width: 720px) {
    .product-name {
      font-size: 1.25rem;
    }
  }
</style>
