import EmbedInterop from "../embed-interop/EmbedInterop"
import type { TriggerLocation } from "../analytics/internal/types"
import type { VariantProduct } from "../types"
import { type AppAnalytics } from "../analytics/appAnalytics"
import type { Recommendation } from "../app/toPage"

export type ProductInContextClick = (
  event: undefined | MouseEvent | KeyboardEvent,
  location: TriggerLocation,
  clickType: "LINK" | "LINK_RIGHT_CLICK"
) => void

export type ProductClick = (
  event: undefined | MouseEvent | KeyboardEvent,
  product: Recommendation | VariantProduct,
  location: TriggerLocation,
  clickType: "LINK" | "LINK_RIGHT_CLICK"
) => void

export const productClickHandler = (
  appAnalytics: AppAnalytics,
  advisorId: string,
  openNewTab: boolean = false
) => {
  return {
    measureProductClick: (
      product: Recommendation | VariantProduct,
      clickType: "LINK_RIGHT_CLICK" | "LINK",
      triggerLocation: TriggerLocation
    ) => {
      appAnalytics.clickedOnProduct(product, clickType, triggerLocation)
    },
    onClick: (
      event: KeyboardEvent | MouseEvent | undefined,
      product: Recommendation | VariantProduct,
      isProductCheckProduct: boolean,
      triggerLocation: TriggerLocation,
      clickType: "LINK_RIGHT_CLICK" | "LINK"
    ) => {
      event?.preventDefault()
      if (product.productUrl) {
        appAnalytics.clickedOnProduct(product, clickType, triggerLocation)

        const gotoProduct = () => {
          if (openNewTab || event?.ctrlKey || event?.metaKey) {
            const anchor = document.createElement("a")
            anchor.href = product.productUrl as string
            anchor.target = "_blank"
            anchor.click()
          } else if (window.top) {
            window.top.location.href = product.productUrl as string
          }
        }
        if (clickType === "LINK") {
          const timeout = isProductCheckProduct
            ? () => EmbedInterop.closeModal(advisorId)
            : gotoProduct

          const tm = setTimeout(timeout, !!openNewTab ? 0 : 500)
          return () => clearTimeout(tm)
        }
      }
    },
  }
}
