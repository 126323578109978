<script lang="ts">
  import RegularText from "./RegularText.svelte"
  import SmallText from "./SmallText.svelte"
  import Title from "./Title.svelte"
  import Markdown from "./Markdown.svelte"

  export let title: string = ""
  export let text: string = ""
  export let markdownTextAllowed: "extended" | "default" = "extended"
  export let smallText: string = ""
</script>

<div class="top-header">
  {#if title}
    <Title>{title}</Title>
  {/if}
  <div class="text-content">
    {#if text}
      <RegularText>
        <Markdown source={text} allowed={markdownTextAllowed} />
      </RegularText>
    {/if}
    {#if smallText}
      <SmallText>
        <Markdown source={smallText} allowed={"links-only"} />
      </SmallText>
    {/if}
  </div>
  {#if $$slots.default}
    <div class="media">
      <slot></slot>
    </div>
  {/if}
</div>

<style>
  .top-header {
    padding-top: 1.5rem;
    margin-bottom: 1rem;
    max-width: var(--max-app-width);
  }
  @media screen and (min-width: 640px) {
    .top-header {
      padding-top: 2rem;
      margin-bottom: 1.5rem;
    }
  }
  .media {
    margin-top: 1rem;
    max-width: 560px;
  }
  .text-content {
    max-width: 40rem;
  }
</style>
