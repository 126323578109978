import type {
  AdviceCustomerInfoConfig,
  CustomerInfoFormConfig,
} from "../../types"

export function parseCustomerInfoNoResultsConfig(
  properties: Record<string, string>
): CustomerInfoFormConfig {
  return {
    enabled: properties["noResultsCustomerInfoShowForm"] === "true",
    showCheckbox: properties["noResultsCustomerInfoShowCheckbox"] === "true",
    checkboxLabel: properties["noResultsCustomerInfoCheckboxLabel"] || "",
    formSubmittedMessage:
      properties["noResultsCustomerInfoSubmittedMessage"] || "",
  }
}

export function parseFormIdentifier(properties: Record<string, string>) {
  return properties["customerInfoFormIdentifier"] || ""
}

export function parseCustomerInfoFormConfig(
  properties: Record<string, string>
): CustomerInfoFormConfig {
  return {
    enabled: properties["customerInfoShowForm"] === "true",
    showCheckbox: properties["customerInfoShowCheckbox"] === "true",
    checkboxLabel: properties["customerInfoCheckboxLabel"] || "",
    formSubmittedMessage: properties["customerInfoFormSubmittedMessage"] || "",
  }
}

export function parseCustomerInfoAdviceConfig(
  properties: Record<string, string>
): AdviceCustomerInfoConfig {
  return {
    enabled: properties["customerInfoShowForm"] === "true",
    showCheckbox: properties["customerInfoShowCheckbox"] === "true",
    checkboxLabel: properties["customerInfoCheckboxLabel"] || "",
    formSubmittedMessage: properties["customerInfoFormSubmittedMessage"] || "",

    ctaButtonLabel: properties["customerInfoCtaButtonLabel"] || "",
    title: properties["customerInfoTitle"] || "",
    intro: properties["customerInfoIntro"] || "",
  }
}

// formIdentifier: properties[keyPrefix + "Identifier"] || "",
