import type {
  AidenResponse,
  Mode,
  PdpCard,
  ProductCardPayload,
  Screen,
  StyleConfig,
} from "../types"
import type { ProductCheckConfig } from "./types"
// @ts-ignore
import imageUrl from "./dummy-product-image.jpg"

const introScreen: Screen = {
  id: "string",
  title: null,
  component: "intro",
  properties: {},
  controls: [
    {
      component: "h1",
      id: "h1",
      label: "Header",
      payload: {},
      properties: {},
      complete: false,
    },
    {
      component: "p",
      id: "p",
      label:
        "Integer posuere erat a ante venenatis dapibus posuere velit aliquet.",
      payload: {},
      properties: {},
      complete: false,
    },
    {
      component: "info-p",
      id: "intro-info",
      label: "Proin tincidunt ex ligula, quis rutrum ante tempus et.",
      payload: {},
      properties: {},
      complete: false,
    },
    {
      component: "button",
      id: "button",
      label: "Start",
      payload: {},
      properties: {},
      complete: false,
    },
  ],
}

const infoScreen: Screen = {
  id: "string",
  title: null,
  component: "info",
  properties: {},
  controls: [
    {
      component: "h1",
      id: "h1",
      label: "Header",
      payload: {},
      properties: {},
      complete: false,
    },
    {
      component: "p",
      id: "p",
      label:
        "Integer posuere erat a ante venenatis dapibus posuere velit aliquet.",
      payload: {},
      properties: {},
      complete: false,
    },
    {
      component: "info-p",
      id: "intro-info",
      label: "Proin tincidunt ex ligula, quis rutrum ante tempus et.",
      payload: {},
      properties: {},
      complete: false,
    },
  ],
}

const questionScreen: Screen = {
  id: "string",
  title: null,
  component: "question",
  properties: {},
  controls: [
    {
      component: "h1",
      id: "h1",
      label: "Question",
      payload: {},
      properties: {},
      complete: true,
    },
    {
      component: "p",
      id: "p",
      label:
        "Integer posuere erat a ante venenatis dapibus posuere velit aliquet.",
      payload: {},
      properties: {},
      complete: true,
    },
    {
      component: "checkbox",
      id: "checkbox",
      label: "Start",
      payload: {},
      properties: {
        "a1.help":
          "Integer posuere erat a ante venenatis dapibus posuere velit aliquet.",
      },
      attributeInstanceId: "a1@entity$0",
      options: [
        {
          label: "First answer",
          value: "b1",
        },
        {
          label: "Second answer",
          value: "a2",
        },
      ],
      complete: true,
    },
  ],
}
const productPayload: ProductCardPayload = {
  clientProductId: "clientProductId",
  data: {},
  bestMatchText: "Best match",
  showBestMatch: true,
  showMatchingIndicators: true,
  showPrice: true,
  productId: "productId",
  productUrl: "#",
  imageUrl: imageUrl,
  productName: "Product Name",
  price: "€24.99",
  salePrice: "€19.99",
  pricePrefix: "",
  priceSuffix: "",
  ctaType: "ProductLink",
  variants: [],
  features: [
    {
      score: "perfect",
      answer: "Perfect match",
    },
    {
      score: "good",
      answer: "Good match",
    },
    {
      score: "neutral",
      answer: "Neutral match",
    },
    {
      score: "bad",
      answer: "Bad match",
    },
  ],
  cta: "View",
  fields: [
    {
      label: "Product description",
      suffix: "",
      values: ["Fusce dapibus, tellus ac cursus ut fermentum massa."],
    },
  ],
  specification: {
    price: {
      value: 24.99,
      currency: "EUR",
    },
  },
  showProductLink: true,
  salePeriodStart: null,
  salePeriodEnd: null,
  reviewScore: null,
  reviewCount: null,
}

const adviceScreen: Screen = {
  id: "string",
  title: null,
  component: "advice",
  properties: {},
  controls: [
    {
      component: "h1",
      id: "h1",
      label: "The advice",
      payload: {},
      properties: {},
      complete: false,
    },
    {
      component: "h2",
      id: "h2",
      label:
        "Integer posuere erat a ante venenatis dapibus posuere velit aliquet.",
      payload: {},
      properties: {},
      complete: false,
    },
    {
      component: "p",
      id: "advice-info",
      label: "Proin tincidunt ex ligula, quis rutrum ante tempus et.",
      payload: {},
      properties: {
        className: "info",
      },
      complete: false,
    },
    {
      component: "info-p",
      id: "advice-info",
      label: "Proin tincidunt ex ligula, quis rutrum ante tempus et.",
      payload: {},
      properties: {},
      complete: false,
    },
    {
      component: "results",
      id: "results",
      label: "Some label",
      payload: {},
      properties: {},
      complete: false,
      controls: Array.from(Array(3).keys()).map((i) => ({
        component: "product-card",
        id: "product-card" + i,
        label: "Some label",
        payload: {
          clientProductId: "clientProductId",
          data: {},
          bestMatchText: "Best match",
          showBestMatch: true,
          showMatchingIndicators: true,
          showPrice: true,
          productId: "productId",
          productUrl: "#",
          imageUrl: imageUrl,
          productName: "Product Name",
          price: "€24.99",
          salePrice: "€19.99",
          salePeriodStart: null,
          salePeriodEnd: null,
          reviewScore: null,
          reviewCount: null,
          features: [
            {
              score: "perfect",
              answer: "Perfect match",
            },
          ],
          cta: "View",
          ctaType: "ProductLink",
          fields: [
            {
              label: "Product description",
              suffix: "",
              values: ["Fusce dapibus, tellus ac cursus ut fermentum massa."],
            },
          ],
          specification: {
            price: {
              value: 24.99,
              currency: "EUR",
            },
          },
          pricePrefix: "",
          priceSuffix: "",
          variants: [],
          showProductLink: true,
        },
        properties: {},
        complete: false,
        controls: [],
      })),
    },
  ],
}
const noResultsAdviceScreen: Screen = {
  id: "string",
  title: null,
  component: "advice",
  properties: {},
  controls: [
    {
      component: "h1",
      id: "h1",
      label: "The advice",
      payload: {},
      properties: {},
      complete: false,
    },
    {
      component: "h2",
      id: "h2",
      label:
        "Integer posuere erat a ante venenatis dapibus posuere velit aliquet.",
      payload: {},
      properties: {},
      complete: false,
    },
    {
      component: "p",
      id: "advice-info",
      label: "Proin tincidunt ex ligula, quis rutrum ante tempus et.",
      payload: {},
      properties: {
        className: "info",
      },
      complete: false,
    },

    {
      component: "results",
      id: "results",
      label: "Some label",
      payload: {},
      properties: {},
      complete: false,
      controls: [],
    },
  ],
}
const productCheckAdviceScreen: (
  config: ProductCheckConfig,
  score: string | undefined,
  resultSize: number
) => Screen = (cfg, score, resultSize): Screen => {
  const productCheckScore = score || "GOOD"
  return {
    id: "string",
    title: null,
    component: "advice",
    properties: {},
    controls: [
      {
        component: "h1",
        id: "h1",
        label: "The advice page",
        payload: {},
        properties: {},
        complete: false,
      },
      {
        component: "h2",
        id: "h2",
        label:
          "Integer posuere erat a ante venenatis dapibus posuere velit aliquet.",
        payload: {},
        properties: {},
        complete: false,
      },
      {
        component: "p",
        id: "advice-info",
        label: "Proin tincidunt ex ligula, quis rutrum ante tempus et.",
        payload: {},
        properties: {
          className: "info",
        },
        complete: false,
      },
      {
        component: "info-p",
        id: "advice-info",
        label: "Proin tincidunt ex ligula, quis rutrum ante tempus et.",
        payload: {},
        properties: {},
        complete: false,
      },
      {
        component: "pdp-card",
        id: "pdp-card",
        label: "Proin tincidunt ex ligula, quis rutrum ante tempus et.",
        payload: {
          ...productPayload,
          "pdp-match": productCheckScore,
          productCheckMatch: productCheckScore,
          productCheckGoodMatchIntro: cfg.goodMatchIntro,
          productCheckGoodMatchLabel: cfg.goodMatchLabel,
          productCheckGoodMatchAlternativeLabel: cfg.goodMatchAlternativeLabel,
          productCheckBadMatchIntro: cfg.badMatchIntro,
          productCheckBadMatchLabel: cfg.badMatchLabel,
          productCheckBadMatchAlternativeLabel: cfg.badMatchAlternativeLabel,
          productCheckBadMatchNoAlternativesIntro:
            cfg.badMatchNoAlternativesIntro,
        },
        properties: {},
        complete: false,
      } as PdpCard,
      {
        component: "results",
        id: "results",
        label: "Some label",
        payload: {},
        properties: {},
        complete: false,
        controls: Array.from(Array(resultSize).keys()).map((i) => ({
          component: "product-card",
          id: "product-card" + i,
          label: "Some label",
          payload: productPayload,
          properties: {},
          complete: false,
          controls: [],
        })),
      },
    ],
  }
}
const blankScreen = {
  id: "string",
  title: null,
  component: "intro",
  controls: [],
  properties: {},
}

const defaultStyleConfig = {
  background: "#f4f4f4",
  focus: "#ff0000",
  font: "Montserrat",
  fontHeader: "Montserrat",
  header: [],
  primary: "#ffd600",
  selectHover: "#c3c3c3",
  text: "#333333",
  headerColor: "string",
  buttonPaddingVertical: "0.5rem",
  buttonPaddingHorizontal: "0.5rem",
  borderRadius: "4px",
  borderRadiusCard: "2px",
  boxShadowOn: true,
}

const defaultProductCheckConfig = {
  enabled: false,
  goodMatchIntro: "",
  goodMatchLabel: "",
  goodMatchAlternativeLabel: "",
  badMatchIntro: "",
  badMatchNoAlternativesIntro: "",
  badMatchLabel: "",
  badMatchAlternativeLabel: "",
}

export type ScreenType =
  | "intro"
  | "info"
  | "advice"
  | "no-results"
  | "question"
  | "product-check-advice"
  | "product-check-bad-advice"
  | "product-check-bad-no-alt-advice"

const composeScreen = (
  screenType: ScreenType,
  productCheckConfig: ProductCheckConfig,
  style: StyleConfig | undefined = defaultStyleConfig,
  defaultMode: Mode | undefined = "inpage"
): AidenResponse => {
  let screen: Screen = blankScreen
  if (screenType === "intro") {
    screen = introScreen
  }

  if (screenType === "info") {
    screen = infoScreen
  }

  if (screenType === "advice") {
    screen = adviceScreen
  }

  if (screenType === "no-results") {
    screen = noResultsAdviceScreen
  }

  if (screenType === "product-check-advice") {
    screen = productCheckAdviceScreen(productCheckConfig, "GOOD", 3)
  }
  if (screenType === "product-check-bad-advice") {
    screen = productCheckAdviceScreen(productCheckConfig, "BAD", 3)
  }
  if (screenType === "product-check-bad-no-alt-advice") {
    screen = productCheckAdviceScreen(productCheckConfig, "BAD", 0)
  }

  if (screenType === "question") {
    screen = questionScreen
  }

  return {
    context: {},
    currentScreenId: "string",
    entities: [],
    history: [],
    nextScreenId: null,
    prevScreenId: null,
    screen: screen,
    screenReportGroups: [],
    sessionId: "string",
    styleConfig: style,
    styleSheet: null,
    locale: "en-US",
    advisor: { id: "preview", name: "preview" },
    integrations: [],
    testVariant: null,
    productFocus: null,
  }
}

export default {
  introScreen,
  infoScreen,
  questionScreen,
  adviceScreen,
  productCheckAdviceScreen,
  blankScreen,
  composeScreen,
  noResultsAdviceScreen,
  defaultProductCheckConfig,
  defaultStyleConfig,
}
