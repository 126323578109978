import type { Traceability } from "../types"

export function sortTraceabilityByScore(
  features: Traceability[],
  keep: Traceability["score"][] = ["perfect", "good", "bad"],
  order: "asc" | "desc" = "desc"
): Traceability[] {
  const sorted = features
    .filter((f) => keep.includes(f.score))
    .sort((a, b) => {
      if (a.score === b.score) return 0
      if (a.score === "perfect") return -1
      if (b.score === "perfect") return 1
      if (a.score === "good") return -1
      if (b.score === "good") return 1

      return 0
    })

  return order === "desc" ? sorted : sorted.reverse()
}
