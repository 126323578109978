import type { Recommendation } from "../app/toPage"
import {
  IntegrationsAnalytics,
  type SendIntegrationsAnalytics,
} from "./integrations/IntegrationsAnalytics"
import Analytics from "./internal/InternalAnalytics"
import type {
  ActionTriggered,
  SendInternalAnalytic,
  TriggerLocation,
} from "./internal/types"
import type { Integration, VariantProduct } from "../types"
import { writable, type Writable } from "svelte/store"
import { handleInternalAnalytics } from "../app/customerInfo/analyticsCallback"
import Helpers from "./integrations/Helpers"
import InternalAnalytics from "./internal/InternalAnalytics"

/**
 * This object retains context and makes it possible for downstream components
 * to emit analytics events. The kinds of analytics events can currently be
 * divided into three categories:
 *
 * - Product click analytics
 * - Custom action analytics
 * - Customer information form analytics
 *
 * This object handles sending events to both internal and integrations analytics.
 * */
export const appAnalyticsStore = writable<AppAnalytics>(
  createMockAnalyticsEmitter()
)

export const createAnalyticsEmitter = (
  isPreview: boolean,
  advisor: { id: string; name: string },
  abTestVariant: string | null,
  sessionId: string,
  integrations: Integration[],
  sendInternalAnalyticEvent: SendInternalAnalytic,
  integrationAnalytics: SendIntegrationsAnalytics
): AppAnalytics & {
  setContext(
    advisor: { id: string; name: string },
    abTestVariant: string | null,
    sessionId: string,
    integrations: Integration[]
  ): void
} => {
  let advisor_ = advisor
  let abTestVariant_ = abTestVariant
  let sessionId_ = sessionId
  let integrations_ = integrations

  const isRecommendation = (p: Record<string, any>): p is Recommendation =>
    "display" in p

  return {
    setContext(
      advisor: { id: string; name: string },
      abTestVariant: string | null,
      sessionId: string,
      integrations: Integration[]
    ) {
      advisor_ = advisor
      abTestVariant_ = abTestVariant
      sessionId_ = sessionId
      integrations_ = integrations
    },

    clickedOnProduct(product, clickType, triggerLocation) {
      const context = isRecommendation(product)
        ? product.recommendationContext.productContext
        : product.productContext

      const position = isRecommendation(product)
        ? product.recommendationContext.recommendationPosition || null
        : null

      const productData = isRecommendation(product) ? product.productData : {} //Variant products dont have data included currently

      sendInternalAnalyticEvent(
        Analytics.productLinkEvent(
          product.productId,
          clickType,
          context,
          position,
          abTestVariant_,
          triggerLocation
        )
      )

      if (product.productUrl) {
        integrationAnalytics.productClick(
          advisor_.id,
          advisor_.name,
          sessionId_,
          integrations_,
          {
            id: product.productId,
            clientProductId: product.clientProductId,
            name: product.productName,
            image: product.imageUrl,
            link: product.productUrl,
            data: productData,
          }
        )
      }
    },

    customerInformationFormSubmittedEvent(
      form:
        | { id: string; type: "INFO" }
        | { type: "EMPTY_ADVICE" }
        | { type: "ADVICE"; products: Recommendation[] },
      email: string,
      checkbox: { checked: boolean; checkboxText: string } | undefined
    ) {
      handleInternalAnalytics(
        abTestVariant_,
        sendInternalAnalyticEvent,
        checkbox?.checked || false
      )

      switch (form.type) {
        case "ADVICE": {
          integrationAnalytics.customerInfoSubmitted(
            advisor_.id,
            advisor_.name,
            sessionId_,
            isPreview,
            integrations_,
            {
              email: email,
              checkboxChecked: checkbox?.checked || false,
              checkboxText: checkbox?.checkboxText || "",
              products: Helpers.recommendationsToAdvisedProducts(form.products),
            }
          )

          break
        }
        case "EMPTY_ADVICE": {
          integrationAnalytics.noResultsCustomerInfoSubmitted(
            advisor_.id,
            advisor_.name,
            sessionId_,
            isPreview,
            integrations_,
            {
              email: email,
              checkboxChecked: checkbox?.checked || false,
              checkboxText: checkbox?.checkboxText || "",
            }
          )

          break
        }
        case "INFO": {
          integrationAnalytics.infoPageCustomerInfoSubmitted(
            advisor_.id,
            advisor_.name,
            sessionId_,
            isPreview,
            integrations_,
            {
              email: email,
              checkboxChecked: checkbox?.checked || false,
              checkboxText: checkbox?.checkboxText || "",
              formIdentifier: form.id,
            }
          )

          break
        }
      }
    },

    customActionEvent(
      actionIdentifier: string,
      recommendationContext: ActionTriggered["recommendationContext"],
      location: ActionTriggered["location"],
      triggerLocation: ActionTriggered["triggerLocation"],
      products: ActionTriggered["products"]
    ) {
      sendInternalAnalyticEvent(
        InternalAnalytics.customActionEvent(
          actionIdentifier,
          recommendationContext,
          location,
          triggerLocation,
          products,
          abTestVariant_
        )
      )
    },
  }
}

export function createMockAnalyticsEmitter(): AppAnalytics {
  return {
    clickedOnProduct() {},
    customActionEvent() {},
    customerInformationFormSubmittedEvent() {},
  }
}

export interface AppAnalytics {
  clickedOnProduct(
    recommendation: Recommendation | VariantProduct,
    clickType: "LINK" | "LINK_RIGHT_CLICK",
    triggerLocation: TriggerLocation
  ): void

  customerInformationFormSubmittedEvent(
    form:
      | { id: string; type: "INFO" }
      | { type: "EMPTY_ADVICE" }
      | { type: "ADVICE"; products: Recommendation[] },
    email: string,
    checkbox: { checked: boolean; checkboxText: string } | undefined
  ): void

  customActionEvent(
    actionIdentifier: string,
    recommendationContext: ActionTriggered["recommendationContext"],
    location: ActionTriggered["location"],
    triggerLocation: ActionTriggered["triggerLocation"],
    products: ActionTriggered["products"]
  ): void
}
