<script lang="ts">
  import { onMount, afterUpdate } from "svelte"
  import EmbedInterop from "./embed-interop/EmbedInterop"
  import type { IncomingEvent } from "./types"
  import { ResizeObserver } from "@juggle/resize-observer"

  let container: Element

  export let advisorId: string

  const sendDimensions = () => {
    if (!container) return
    const height = container.scrollHeight
    const width = container.scrollWidth

    EmbedInterop.sendDimensionChangeEvent(advisorId, { width, height })
  }

  onMount(() => {
    new ResizeObserver(sendDimensions).observe(container)
    sendDimensions()
  })
  afterUpdate(() => {
    sendDimensions()
  })

  const initMessageHandler = (event: MessageEvent<IncomingEvent>) => {
    if (event.data.type === "init") {
      sendDimensions()
      window.removeEventListener("message", initMessageHandler)
    }
  }
  window.addEventListener("message", initMessageHandler)
</script>

<div bind:this={container}>
  <slot />
</div>
