import { writable, type Writable } from "svelte/store"
import type {
  Action,
  ActionOptions,
  TransitionEvent,
  ActionEvent,
  ActionState,
  StoreExtension,
  ActionCallbackEvent,
} from "./types"

/**
 * A Svelte store used to track the state when a CustomAction is performed.
 *
 * A starting action is ran, after which responses are expected back through
 * the "message" event channel on the `Window` object.
 *
 * State of an action is triggered through a 'triggerId' which should be unique and
 * provided by the callee.
 *
 * A CustomAction can be in one {@link ActionState | ActionStoreState}:
 *
 * - __IDLE:__ - The custom action is not running
 * - __PENDING:__ - The custom action was triggered and is awaiting a response
 * - __ACKNOWLEDGED:__ - An acknowledgement response was received, and the action is
 *    now awaiting a success or failure response.
 * - __SUCCESS:__ - The action was a success. This will reset to IDLE after 3 seconds.
 * - __FAILED:__ - The action was a failure. This will reset to IDLE after 3 seconds.
 *
 * @remarks
 * https://support.aiden.cx/nl/articles/171495-add-to-cart-cta
 * */
export const actionStore = createActionStore()

//
// IMPLEMENTATION
// functions exported below should only be used for testing purposes
//
function createActionStore(): Writable<Record<string, ActionState>> &
  StoreExtension {
  const store = _createActionStore()
  window.addEventListener("message", store.handleEvent)
  return store
}

export function _createActionStore(
  options: ActionOptions = {
    timeoutDelay: 10_000,
    resetDelay: 3_000,
    sendEventsTo: windowEmitter(),
  }
): Writable<Record<string, ActionState>> & StoreExtension {
  const store = writable<Record<string, ActionState>>({})
  const { subscribe, set, update } = store
  const initialState = "IDLE"

  const apply = (
    triggerId: string,
    arg: [ActionState, Promise<TransitionEvent> | undefined]
  ): ActionState => {
    const [newState, effect] = arg
    store.update((s) => ({ ...s, [triggerId]: newState }))

    effect?.then((event) => {
      store.update((s) => {
        const state = s[triggerId]
        if (state) {
          return {
            ...s,
            [triggerId]: apply(triggerId, updateState(event, state, options)),
          }
        } else {
          return s
        }
      })
    })
    return newState
  }

  const handleEvent = (event: MessageEvent) => {
    store.update((s) => {
      const callbackEvent = parseActionCallbackEvent(event)
      if (!callbackEvent) return s
      const state = s[callbackEvent.triggerId]

      if (state) {
        const triggerId = event.data.triggerId

        return {
          ...s,
          [triggerId]: apply(
            triggerId,
            updateState(callbackEvent.status, state, options)
          ),
        }
      }
      return s
    })
  }

  const trigger = (
    triggerId: string,
    action: {
      advisorId: string
      advisorName: string
    } & Action
  ) => {
    const event: ActionEvent = {
      advisorId: action.advisorId,
      advisorName: action.advisorName,
      type: "action",
      data: {
        triggerId: triggerId,
        actionId: action.actionId,
        products: action.products,
      },
    }
    options.sendEventsTo(event)

    update((s) => {
      return {
        ...s,
        [triggerId]: apply(
          triggerId,
          updateState("START", initialState, options)
        ),
      }
    })
  }

  return {
    subscribe,
    set,
    update,
    trigger: trigger,
    handleEvent: handleEvent,
  }
}

function windowEmitter() {
  return (event: ActionEvent) => window.parent.postMessage(event, "*")
}

function updateState(
  transition: TransitionEvent,
  state: ActionState,
  options: ActionOptions
): [ActionState, Promise<TransitionEvent> | undefined] {
  if (transition === "START" && state === "IDLE") {
    return ["PENDING", timeoutAfter(options.timeoutDelay)]
  }

  if (transition === "TIMED_OUT" && state === "PENDING") {
    return ["FAILED", after(options.resetDelay, "RESET")]
  }

  if (transition === "ACKNOWLEDGED" && state === "PENDING") {
    return ["ACKNOWLEDGED", undefined]
  }

  if (
    transition === "SUCCEEDED" &&
    (state === "PENDING" || state === "ACKNOWLEDGED")
  ) {
    return ["SUCCESS", undefined]
  }

  if (
    transition === "FAILURE" &&
    (state === "PENDING" || state === "ACKNOWLEDGED")
  ) {
    return ["FAILED", after(options.resetDelay, "RESET")]
  }

  if (transition === "RESET" || transition === "ABORTED") {
    return ["IDLE", undefined]
  }

  return [state, undefined]
}

//
// HELPERS
//

async function timeoutAfter(duration: number = 10_000): Promise<TransitionEvent> {
  return after(duration, "TIMED_OUT")
}

async function after<T>(duration: number = 10_000, value: T): Promise<T> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(value)
    }, duration)
  })
}

function parseActionCallbackEvent(
  event: MessageEvent<unknown>
): ActionCallbackEvent | undefined {
  const data = event.data
  if (!(typeof data === "object" && data !== null)) return
  if (
    !(
      "advisorId" in data &&
      "advisorName" in data &&
      "actionId" in data &&
      // "products" in data &&
      "type" in data &&
      "triggerId" in data &&
      "status" in data &&
      typeof data.advisorId === "string" &&
      typeof data.advisorId === "string" &&
      typeof data.advisorName === "string" &&
      typeof data.triggerId === "string" &&
      typeof data.type === "string" &&
      typeof data.actionId === "string" &&
      typeof data.status === "string" &&
      data.type === "action-callback"
    )
  ) {
    return
  }
  const status_ = data.status.toUpperCase()
  const status =
    status_ === "SUCCEEDED"
      ? "SUCCEEDED"
      : status_ === "FAILURE"
      ? "FAILURE"
      : status_ === "ACKNOWLEDGED"
      ? "ACKNOWLEDGED"
      : status_ === "ABORTED"
      ? "ABORTED"
      : undefined
  if (!status) return

  return {
    type: data.type,
    advisorId: data.advisorId,
    advisorName: data.advisorName,
    actionId: data.actionId,
    products: [],
    triggerId: data.triggerId,
    status: status,
  }
}
