<h1>
  <slot />
</h1>

<style>
  h1 {
    color: var(--aiden-title-color, #000);
    font-weight: var(--aiden-title-font-weight, 600);
    font-family: var(--aiden-title-font, inherit);
    font-size: clamp(1rem, var(--aiden-title-font-size, 2rem), 3rem);
    text-decoration: var(--aiden-title-text-case, default);
    margin-top: 0;
    margin-bottom: 1rem;
    word-break: break-word;
    text-wrap: balance;
    line-height: 1.25;
  }
  @media screen and (max-width: 640px) {
    h1 {
      font-size: clamp(1rem, var(--aiden-title-font-size, 2rem), 2.5rem);
    }
  }
  h1:empty {
    display: none;
  }
</style>
