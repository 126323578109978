<script lang="ts">
  import type { EnergyClassification } from "../../types"

  export let score: string
  export let scale: string

  const colors: Record<string, Record<string, string>> = {
    "EU-G-A": {
      G: "#EF1823",
      F: "#F8711F",
      E: "#FFBB08",
      D: "#FFF302",
      C: "#C2D927",
      B: "#4DBB45",
      A: "#00A650",
    },
    "EU-G-A+++": {
      G: "#EF1823",
      F: "#EF1823",
      E: "#EF1823",
      D: "#EF1823",
      C: "#F8711F",
      B: "#FFBB08",
      A: "#FFF302",
      "A+": "#C2D927",
      "A++": "#4DBB45",
      "A+++": "#00A650",
    },
    "EU-D-A+++": {
      D: "#EF1823",
      C: "#F8711F",
      B: "#FFBB08",
      A: "#FFF302",
      "A+": "#C2D927",
      "A++": "#4DBB45",
      "A+++": "#00A650",
    },
    "EU-E-A++": {
      E: "#EF1823",
      D: "#F8711F",
      C: "#FFBB08",
      B: "#FFF302",
      A: "#C2D927",
      "A+": "#4DBB45",
      "A++": "#00A650",
    },
  }

  let color = "#ccc"
  $: {
    let colorScale = colors[scale.toUpperCase()]
    if (colorScale) {
      color = colorScale[score.toUpperCase()]
    }
  }
</script>

<div class="energy-label" style={`--energy-label-color: ${color}`}>
  <div aria-hidden="true" class="score">
    <div class="score-indicator">
      {#if score[0] === "A" && score[1] === "+"}
        <span>A</span>
        <sup class="score-plusses"> {score.split("A")[1]} </sup>
      {:else}
        {score.toUpperCase()}
      {/if}
    </div>
  </div>
  <div class="arrow" aria-hidden="true">
    <div></div>
  </div>
</div>

<style>
  .energy-label {
    --label-height: 24px;

    display: grid;
    grid-template-columns: 38px 12px;
    grid-template-rows: var(--label-height);
    height: var(--label-height);

    --border-width: 1px;
    --border-color: #000;
    --energy-label-color: orange;
    position: relative;
    color: black;
  }

  .score-plusses {
    font-size: 9px;
    -webkit-text-stroke: 0;
  }

  .score {
    color: white;
    height: calc(var(--label-height));
    box-sizing: border-box;
    font-size: 1.2rem;
    font-weight: 900;
    display: grid;
    grid-template-rows: 1fr;
    place-content: center;
    line-height: 1;
    place-items: center;
    justify-content: center;
    background-color: var(--energy-label-color);
    border-top: var(--border-width) var(--border-color) solid;
    border-bottom: var(--border-width) var(--border-color) solid;
    border-left: var(--border-width) var(--border-color) solid;
  }
  .score-indicator {
    font-family: Calibri, sans-serif;
    line-height: 1;
    -webkit-text-stroke: 1px #000;
    display: flex;
    flex-wrap: nowrap;
  }

  .arrow {
    --arrow-height: calc(var(--label-height) / 2);
    z-index: 1;
    position: relative;
    box-sizing: border-box;
    width: 12px;
    height: var(--label-height);
    overflow: hidden;
  }
  .arrow:after {
    /* position: absolute; */
    width: calc(var(--label-height) * 0.7);
    height: calc(var(--label-height) * 0.7);
    border: 1px solid var(--border-color);
    background-color: var(--energy-label-color);
    transform: rotate(45deg);
    transform-origin: left top;
    content: "";
    display: block;

    box-sizing: border-box;
  }
</style>
