<svelte:options accessors customElement="aiden-product-check-preview" />

<script lang="ts">
  import type {
    AidenResponse,
    Config,
    GetAttribute,
    OptionsFor,
    SetAttribute,
    StyleConfig,
  } from "../types"
  import { changeTheme } from "../appStyle"
  import { i18n } from "../lang/i18n"
  import type { IntegrationsAnalyticsForComponents } from "../analytics/integrations/IntegrationsAnalytics"
  import screenDefinitions, {
    type ScreenType,
  } from "../preview/screenDefinitions"
  import type { ProductCheckConfig } from "../preview/types"
  import PageFrame from "../app/PageFrame.svelte"
  import Page from "../app/Page.svelte"
  import { type Page as PageT, toPage } from "../app/toPage"

  export let screenType: ScreenType | undefined = "advice"
  export let productCheckConfig: string = ""
  export let locale: string = "en-US"

  let setAttribute: SetAttribute = () => {}
  let getAttribute: GetAttribute = () => {
    return "a1"
  }

  const customerAnalytics: IntegrationsAnalyticsForComponents = {
    customerInfoSubmitted: () => {},
    infoPageCustomerInfoSubmitted: () => {},
    noResultsCustomerInfoSubmitted: () => {},
    productClick: () => {},
  }

  let checkConfig: undefined | ProductCheckConfig = undefined
  $: checkConfig = JSON.parse(productCheckConfig)

  let styleConfig: StyleConfig = screenDefinitions.defaultStyleConfig
  let previewMode: Config["mode"] = "inpage"
  export let showPoweredByAiden: boolean = false

  let config: Config = {
    advisorId: "",
    mode: previewMode,
    isProductCheck: checkConfig?.enabled || false,
    isPreview: true,
    transparent: false,
    introContent: "full",
    productScope: undefined,
  }

  $: {
    config = {
      ...config,
      mode: previewMode,
    }
  }

  i18n.setupI18n({ withLocale: "en-US" })
  let data: undefined | AidenResponse = undefined
  let page: PageT | undefined = undefined
  $: {
    if (screenType && checkConfig) {
      const data_ = screenDefinitions.composeScreen(
        screenType,
        checkConfig,
        styleConfig
      )
      data = data_
      page = toPage(data_.screen, { introContent: "full" })
    }
  }

  export let progress: number = 30

  $: i18n.changeLocale(locale)

  const optionsFor: OptionsFor = () => Promise.resolve([])

  const advisorId = "preview"
</script>

<main class="aiden-webshop-app preview">
  <PageFrame
    {progress}
    {page}
    hideProgress={true}
    hideStartOver={true}
    embedMode={"fullpage"}
    {advisorId}
    {showPoweredByAiden}
    screenId={data?.screen?.id || ""}
  >
    {#if page}
      <Page {page} hidePrev={true} {setAttribute} {getAttribute} {optionsFor} />
    {/if}
  </PageFrame>
</main>

<style>
  main {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    user-select: none;
    pointer-events: none;
  }
  .preview :global(button),
  .preview :global(a) {
    cursor: default;
  }
  .preview :global(.wrapper:before) {
    display: none;
  }
  .preview :global(.advice:before) {
    display: none;
  }
  .preview :global(.sidebar-container) {
    animation: none;
  }
</style>
