<script lang="ts">
  import ContextMenuHandler from "../../contextMenuHandler"
  import { type ProductInContextClick } from "../../app/productClickHandler"
  import Button from "../Button.svelte"
  export let href: string | undefined = undefined

  export let onClick: ProductInContextClick | undefined = undefined

  export let target: string | undefined = "_parent"
  export let testId: string | undefined = ""
</script>

{#if href}
  <a
    class="product-link"
    {href}
    {target}
    on:click={(e) => onClick && onClick(e, "PRODUCT_CTA", "LINK")}
    use:ContextMenuHandler={() =>
      onClick && onClick(undefined, "PRODUCT_CTA", "LINK_RIGHT_CLICK")}
  >
    <Button isFullWidth variant="primary" className="product-link" {testId}>
      <slot />
    </Button>
  </a>
{:else}
  <Button isFullWidth variant="primary" className="product-link">
    <slot />
  </Button>
{/if}
