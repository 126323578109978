const longPressDuration = 610

const Handler = (function () {
  let longPressCountdown: number | null = null
  let scrollY: number | null = null

  const onContextMenu = (contextMenuFn: () => void) => {
    contextMenuFn()
  }

  /* The contextmenu event does not fire on iOS, which is why we need
     touch handlers to determine (by approximation) whether the context menu
     has been opened.
  */
  const onTouchStart = (contextMenuFn: () => void) => {
    /* Check if is iOS */
    if (window.navigator.userAgent.match(/iP(ad|od|hone)/i)) {
      scrollY = window.scrollY
      /* Start counting down the duration of a "long press" */
      longPressCountdown = window.setTimeout(() => {
        /* There is no difference between touchstart and touchcancel by
           which we could check if a user's pointer stayed in one place,
           i.e. if it was a "true" long press. We do however know if a user
           scrolled the page (vertically):
        */
        if (scrollY === window.scrollY) {
          /* Unless the user's pointer was moving horizontally, by now we would
             be correct in guessing that a "long press" has taken place on iOS -
             so let's execute the function.
          */
          contextMenuFn()
        }
        longPressCountdown = null
        scrollY = null
      }, longPressDuration)
    }
  }

  const onTouchMove = () => {
    clear()
  }

  const onTouchCancel = () => {
    clear()
  }

  const onTouchEnd = () => {
    clear()
  }

  const clear = () => {
    longPressCountdown && clearTimeout(longPressCountdown)
    longPressCountdown = null
    scrollY = null
  }

  return {
    onContextMenu,
    onTouchStart,
    onTouchMove,
    onTouchCancel,
    onTouchEnd,
  }
})()

const ContextMenuHandler = (
  node: HTMLElement,
  contextMenuFn: (() => void) | undefined
) => {
  if (contextMenuFn === undefined) return
  const contextMenuHandler = () => Handler.onContextMenu(contextMenuFn)
  const touchStartHandler = () => Handler.onTouchStart(contextMenuFn)

  node.addEventListener("contextmenu", contextMenuHandler)
  node.addEventListener("touchstart", touchStartHandler)
  node.addEventListener("touchcancel", Handler.onTouchCancel)
  node.addEventListener("touchend", Handler.onTouchEnd)

  return {
    destroy() {
      node.removeEventListener("contextmenu", contextMenuHandler)
      node.removeEventListener("touchstart", touchStartHandler)
      node.removeEventListener("touchcancel", Handler.onTouchCancel)
      node.removeEventListener("touchend", Handler.onTouchEnd)
    },
  }
}

export default ContextMenuHandler
