import type { Translations } from "../types"

const translations: Translations = {
  showMore: "Daha fazlasını göster",
  showLess: "Daha az göster",
  bestMatch: "En iyi eşleşme",
  view: "Bakmak",
  customAction: "Alışveriş sepetinde",
  notFound: "404: Bulunamadı",
  reset: "Yeniden başla",
  close: "Kapalı",
  noAdviceTitle:
    "Şu anda tercihlerinize tam olarak uyan bir ürün mevcut değil.",
  noAdviceMessage: "Tercihlerinizi değiştirebilir veya daha sonra tekrar deneyebilirsiniz.",
  prev: "Öncesi",
  next: "Sonraki",
  backToAdvice: "Tavsiyeye geri dön",
  dontKnow: "Atlamak",
  email: "E-posta adresi",
  send: "Göndermek",
  emailError: "Geçerli bir e",
  readMore: "Devamını oku",
  selectAnAnswer: "Bir yanıt seçin",
  answerNotListed: "Cevabım listelenmiyor",
  whyThisProductForMe: "Bu tavsiye neden?",
  alternative: "alternatif",
  alternatives: "alternatifler",
  seeAlso: "Ayrıca bakınız",
}

export default translations
