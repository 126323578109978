<script lang="ts">
  import EmbedInterop from "./embed-interop/EmbedInterop"
  import modalCloseOnEscape from "./triggers/modalCloseOnEscape"
  import modalFocusTrap from "./triggers/modalFocusTrap"

  let container: HTMLElement

  const modalCloseId = "aiden-modal-close-button"

  export let advisorId: string

  const sendBackgroundClick = (event: KeyboardEvent | MouseEvent) => {
    const target = event.target as Element
    if (container === target || target.id === modalCloseId) {
      event.stopPropagation()
      EmbedInterop.sendBackgroundClickEvent(advisorId)
    }
  }

  $: modalCloseOnEscape(modalCloseId)
  $: modalFocusTrap(container)
</script>

<div bind:this={container} on:click={sendBackgroundClick}>
  <!-- svelte-ignore a11y-invalid-attribute -->
  <a href="#" class="aiden-modal-visually-hidden-anchor" />
  <slot />
  <!-- <Button
    isCircle
    isSmall
    onClick={sendBackgroundClick}
    id={modalCloseId}
    testId={modalCloseId}
  >
    <Cross />
  </Button> -->
</div>

<style>
  div {
    padding: 0.5rem 0.5rem;
    min-height: 100vh;
    box-sizing: border-box;
  }

  .aiden-modal-visually-hidden-anchor {
    position: absolute;
    opacity: 0;
    width: 1px;
    height: 1px;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
  }

  :global(#aiden-modal-close-button) {
    position: fixed;
    top: 0.5rem;
    right: 0.5rem;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  }

  @media screen and (min-width: 640px) {
    :global(#aiden-modal-close-button) {
      top: 0.75rem;
    }
  }

  @media screen and (min-width: 74rem) {
    div {
      padding: 4.5rem 0.5rem;
    }
    :global(#aiden-modal-close-button) {
      right: calc(((100% - 64rem) / 2) - 4rem);
    }
  }
</style>
