<script lang="ts">
  import type { Config } from "./types"
  // @ts-ignore
  import imgUrl from "./icons/made-with-aiden.png"

  export let integrationType: Config["mode"] | undefined = undefined
</script>

<a
  href="https://aiden.cx"
  target="_blank"
  class:in-modal={integrationType === "modal"}
  class:in-page={integrationType === "inpage"}
>
  <img src={imgUrl} alt="Made with Aiden" />
</a>

<style>
  a {
    padding: 0.15rem 0.35rem;
    text-decoration: none;
    line-height: 1;
    border-radius: 0.25rem;
    display: flex;
    width: min-content;
    background: #fff;
  }
  a img {
    filter: grayscale(100%);
  }
  a:hover img {
    filter: grayscale(0%);
  }

  a.in-page {
    margin-left: auto;
    margin-top: 1rem;
    margin-right: 0;
    margin-left: auto;
    margin-bottom: 0;
  }

  a.in-modal {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }

  img {
    width: 6.875rem;
    height: 1rem;
  }
</style>
