<script lang="ts">
  import type { EnergyLabelField } from "../../types"
  import EnergyLabel from "./EnergyLabel.svelte"

  export let eeiProductField: EnergyLabelField
</script>

<div class="field">
  {#if eeiProductField.energyLabel}
    {@const energyLabel = eeiProductField.energyLabel}
    <a class="energy-label" href={energyLabel.labelLink} target="_blank"
      data-testid="energy-label"
    >
      <EnergyLabel
        score={energyLabel.classification}
        scale={energyLabel.scale}
      />
    </a>
  {/if}
  {#if eeiProductField.productSheet}
    <a
      class="field-value"
      href={eeiProductField.productSheet.url}
      target="_blank"
      data-testid="energy-label-product-sheet"
    >
      {eeiProductField.productSheet.label}
    </a>
  {/if}
</div>

<style>
  a.energy-label,
  a.energy-label:visited {
    color: inherit;
    padding: 0.125rem;
    border-radius: 2px;
    text-decoration: none;
    margin-right: 0.25rem;
  }
  a.energy-label:hover,
  a.energy-label:focus-visible {
    background-color: rgb(from var(--aiden-product-card-text-color) r g b / 5%);
  }
  a.field-value {
    text-decoration: underline;
  }
  a.field-value:hover,
  a.field-value:focus-visible {
    text-decoration: none;
  }

  .field {
    font-size: var(--product-card-small-font-size, 0.875rem);
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    & .field-label {
      font-weight: var(--aiden-product-field-label-font-weight, bold);
      color: var(--aiden-product-field-label-color, inherit);
    }

    & .field-value {
      font-weight: var(--aiden-product-field-value-font-weight, normal);
      color: var(--aiden-product-field-value-color, inherit);
      -webkit-line-clamp: 4;
      line-clamp: 4;
      -webkit-box-orient: vertical;
      min-width: 0;
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    & .field-suffix {
      font-weight: var(--aiden-product-field-suffix-font-weight, normal);
      color: var(--aiden-product-field-suffix-color, inherit);
    }
  }
</style>
